// ==========================================================================
// Mobile Custom Styles
// ==========================================================================

// body {padding-top: 90px;} // fixed header offset

.media-state {z-index: 1;}

/* ---------------------
 Universal
 --------------------- */
.view-all {
    display: block;
    margin-left: 0;
    margin-top: -0.5rem;
    padding: 0 0 0.25rem 0 ;
    border-left: none;
}

img { max-width: 100%; width: auto; height: auto;}


/* ---------------------
   Header
   --------------------- */
.header {
    width: 100%;
    .bg-texture-1(@color-primary, 95%);
    .header-quicklinks {
            ul { text-align: center;
                li{margin-right:7px;}
            }
            .dropdown {
                div {right:5%; left:5%;}
                .search-icon-mobile{display: inherit;  position: absolute; top: 1px; right: 0px;
                    .search{margin-left:10px;}
                }
                .cal-map{padding-left:10px;}

                .search-icon-mobile i{border-right: none;}
            }
            .search-bar{
                height: 50px;
                color: #fff;
                font-weight: 600;
                position: absolute;
                top: 47px;
                width: 100%;
                left: 0px;
            }
        }

    // Topbar
    .topbar {
        width: 100%;
        position: relative;

        .grid {
            padding-left: 10px;
            padding-right: 10px;
        }
        
        .logo-container {
            width: 100%;
            margin-top: 4%;
            display: block;
            .logo {
                position: inherit;
                margin: 0 auto;
                max-width: 135px;
                padding: 1px 10px;
                img {max-height: 110px;}
            }  
        }
        .logo-text-container {
            width: 0%; 
            display: table; 
            margin: 0 auto;
        }
        .site-title{
            text-align: center;
            line-height: 1.4;
        }

        .round-link-container {
            width: 100%;
            display: table;
        }
             
        .round-links{ 
            li {
                height: auto;
                width: 45%;
                margin: 2%;
            }
            
            a {
                height: auto;
                width: auto;
                padding: 5px;
                border-radius: 3px;
            }
            
            i { display: inline-block; font-size: 12px; font-size: 0.75rem; margin-right: 5px;}
        }

        .topbar-content{
        margin-top: 11%;
        position: relative;
        }
    }

    // Navbar
    .mobile-nav-button {
        display: block;
        position: absolute;
        top: 50px;
        width: 100%;
        border: none;
    }
    
    .navbar {
        font-family: 'proxima-nova', sans-serif !important;
        position: fixed;
        left: -270px;
        top:0;
        height: 100%;
        width: 260px;
        z-index:999;
        overflow: hidden;
        border-top: none;
        border-bottom: none;
        color: #FFF;
        background: @color-secondary url(../images/menu_bg.png) no-repeat center top;
        #shadow > .inner-shadow (-4px, 0, 3px, 0.35);
        
        .grid {padding: 0;}
    
        .nav-outer {
            position: relative;
            width: 300px;
            height:100vh;
            padding-top: 60px;
            overflow-x: hidden; 
        }
        
        .scroll-marker {
            display: none;
        
            &:before {
                display: block;
                font-family: @font-icon;
                background: lighten(@color-secondary, 15%);
                font-size: 24px;
                font-size: 1.5rem;
                line-height: 20px;
                line-height: 1.25rem;
                content: "\f0d7";
                width: 60px;
                position: fixed;
                left: 100px;
                bottom: 0;
                z-index: 920;
                .border-radius(2px 2px 0 0);
                text-align: center;
            } 
        }
        
        .nav-inner {
            position: absolute;
            width: 260px;
            left:0;
        }
        
        .mobile-control {
            display: inline-block;
            position: absolute;
            top: -46px;
            left: 180px;
            
            
            a {
                display: inline-block !important;
                cursor: pointer;
                text-transform: uppercase !important;
                color: #FFF !important;
                font-size: 10px !important;
                font-size: 0.625rem !important;
                line-height: 1.2;
                padding: 6px 12px !important; 
                border: 1px solid #FFF !important;
                border-radius: 2px;
                background: @color-secondary;
                width: 100% !important;
                height: auto !important;
                
                &:hover {
                    background: none !important;
                }
            }
        }

        .menu-list { 
            padding: 0; 
            display: block;
        }

        .menu-list > li {
            display: block;
            position: inherit;
            
            &:hover > a {background: transparent;}

            /* Main Nav Links */
            & > a {
                display: block;
                color: #FFF;
                font-family: inherit;
                font-size: 16px;
                font-size: 1rem;
                text-transform: none;
                padding: 15px 25px;
                padding:  0.9375rem 1.5625rem;
                font-weight: 300;
                line-height: 1.2;
                outline: none;
                height: 3rem;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                
                &:hover {
                    color: inherit;
                    background: transparent;
                }
            }

            /* For primary links */
            &.has-children {
                & > a {
                    width: 80%;
                }
                & > a:after {
                    display: none;
                }
                
                &:after {
                    font-family: FontAwesome;
                    padding: 0 0 0 0.5rem;
                    content: "\f178";
                    font-size: 14px;
                    font-size: 0.875rem;
                    position: absolute;
                    right: 20px;
                    line-height: 2.75rem;
                }
            }

            /* Universal child menu styles/resets */
            ul {
                display: none;
                list-style-type: none;
                position: absolute;
                width: 260px;
                top: 0;
                left: 260px;
                padding: 0 !important;
                border: 0 !important;
                opacity: 1;
                background: transparent !important;
                margin-bottom: 30px;

                li {position:static;}

                a {
                    display: block;
                    color: #FFF;
                    
                    font-size: 16px;
                    font-size: 1rem;
                    text-transform: none;
                    padding: 15px 25px;
                    padding:  0.9375rem 1.5625rem;
                    font-weight: 300;
                    line-height: 1.2;
                    border-top: none;
                    height: 3rem;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    &:hover {
                        color: inherit;
                        background: transparent;
                    }
                }
            }
            
            /* Iems with child menus */
            .has-children {
                & > a {
                    width: 80%;
                }
                
                & > a:after {
                    display:none;
                }
                
                &:after {
                    font-family: FontAwesome;
                    padding: 0 0 0 0.5rem;
                    content: "\f178";
                    font-size: 14px;
                    font-size: 0.875rem;
                    position: absolute;
                    right: 20px;
                    line-height: 2.75rem;
                    margin-top: -2.75rem;
                }
                
                & > ul {left: 260px !important;}
            }

            /* 1st child menu styles */
            & > ul {
                background: transparent;

                & > li {
                    a {background: transparent;}
                }
            }

            /* 2nd -> nth child menu styles */
            & > ul > li ul { 

                & > li {
                    &:first-of-type:before {
                        display: none;
                    }

                    a {
                        border-left: none;
                        background: transparent;
                    }
                }
            }
        } 
        
    }
}


/* ---------------------
     Banner
     --------------------- */
.banner {

    .grid,
    .ccms-banner, 
    .banner-slide, .slider {
      min-height: @banner-height-mobile;
      max-height: @banner-height-mobile;
    }

    .grid, .col {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .ccms-banner {
        .ccms-banner-overlay {
            top: auto;
            width: 90%;
            max-width: 90%;
            margin: auto;
            left: 0;
            right: 0;
            padding: 20px 20px 20px 20px;
            text-align: center;
            h1 {
              font-size: 25px;
              font-size: 1.5625rem;
              margin-bottom: 0;
              letter-spacing: 0;
            }
            p {display: none;}
        }
    }
    
    .ccms-banner-slider .slick-dots {
        position: absolute;
        max-width: 80%;
        width: auto;
        text-align: center;
        left: 13% !important;
        right: 0% !important;
        padding: 0;
    }

    .ccmsSide{
        width: 100%;
    }
}

.sub-page {
    #previewPanel,
    .banner {
        background: #FFF;

         .grid,
        .ccms-banner, 
        .banner-slide, .slider {
            min-height: @banner-height-subpage;
            max-height: @banner-height-subpage;
            overflow: hidden;
        }

        .banner-slide {min-height: @banner-height-subpage;}
        
        .ccms-banner-overlay, .slick-next::before, .slick-prev::before, .slick-prev, .slick-next, .slick-dots {
            display: none !important;
        }
        
    }
}


/* ---------------------
	 Slide Away
--------------------- */
.slide-away {    
    .sa-title, .sa-content {
        width: 100%;
        float: none;
        min-height: 85px;
        padding: 30px 0;
        
        .half-container {
            max-width: @desktop-container;
            padding-left: @container-padding;
            padding-right: @container-padding;
            position: relative;
        }
    }
}

/* ---------------------
	 Awards
--------------------- */
//.awards {
//    .award-images {
//        width: 100%;
//        float: none;
//        min-height: 1px;
//    }
//}

/* ---------------------
	 Latest
--------------------- */
.latest-color-box {
    margin: 0 0 -2em;
}

/* ---------------------
	 Info Modules
--------------------- */
.info-modules-container {
    .daily-bulletins {
        .icon-title-small {display: block;}
        .date { 
            width: 48%;
            margin: 2% 0;
            &:nth-child(odd) {margin-left: 2%;}
        } 
        .view-more {float:none; clear: left; display: block;}
    }
    
    .social-links .social-text {
        display: block;
    }
}



/* ---------------------
   Content
   --------------------- */
.content {
   
} // end content


/* ---------------------
   Dashboard
   --------------------- */
a.dashboard-item {
    margin-bottom: @container-padding/2;
}

/* ---------------------
   Social
   --------------------- */
.social {
  .social-buttons {display: block;}
}


/* ---------------------
   Footer
   --------------------- */

.footer-top {
    text-align: center;
    font-size: 21px;
    font-size: 1.3125rem;
    
    .col:last-child {text-align: inherit;}
}

.footer {
    .grid {
      padding-left: 30px;
      &::before {display: none !important;}
     h3, ul {text-align: center;}
        .contact { padding-bottom: 1rem;
            li, .subpages & li, span.address-city {display: block;}
        }
    }
}

.copyright {
    span {
        display: block;
        text-align: center;
        width: 100%;
    }

    span:last-child {
        float: none;
    }
}