// ==========================
// Style Variables
// ==========================

// Fonts
@font-primary:			    'Open Sans', sans-serif;
@font-secondary:			'Open Sans', sans-serif;
@font-tertiary:		        'Bree Serif', serif;	
@font-icon:					'FontAwesome';

// Text Sizes
@font-size:   				16px;
@line-height: 				1.6;
 
// Colors - Backgrounds
@color-primary:				#002583; //blue
@color-secondary:			#997d61; //light brown
@color-navbar:				#2d261a; //dark brown
@color-body:				@color-secondary;

// Colors - Type
@color-text:  			    #444;
@color-link:  			    #1cadeb; //baby blue
@color-line:			    #eeeeee;
@color-link-hover: 		    darken(@color-link, 30%);
@color-navbar-text:		    #fff; 