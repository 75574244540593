// ==========================================================================
// Desktop Custom Styles
// ==========================================================================

@import url('https://fonts.googleapis.com/css?family=Bree+Serif|Open+Sans:300,400,400i,600,700,700i');

/* ---------------------
 Body
 --------------------- */

html,
body {
    font-weight: 300;
	/*overflow-x: hidden;*/
    background-color: @color-body;
}

.ada {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

#skiptocontent a {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border-bottom: 1px solid #ffffff;
    border-bottom-right-radius: 8px;
    border-right: 1px solid #ffffff;
    color: contrast(@color-secondary, #111, #fff);
    background:@color-secondary;
    left: 0;
    padding: 6px;
    position: absolute;
    top: -40px;
    transition: top 1s ease-out 0s, background 1s linear 0s;
    z-index: 100;
}
#skiptocontent a:focus {
    background: #bf1722 none repeat scroll 0 0;
    color: #fff;
    left: 0;
    outline: 0 none;
    position: absolute;
    top: 0;
    transition: top 0.1s ease-in 0s, background 0.5s linear 0s;
}
.back-to-top {
   background-color: rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    bottom: 50px;
    color: #ffffff;
    cursor: pointer;
    display: none;
    font-size: 20px;
    height: 40px;
    left: auto;
    line-height: 40px;
    position: fixed;
    right: 30px;
    text-align: center;
    top: auto;
    width: 40px;
    z-index: 299;
    &:focus {
        outline: none;
    }
    &:hover {
        color: @color-secondary;
    }
}

.rspPane div#broadcastMessageHolder {
    display: none;
}

::selection {background: @color-primary; color: #FFF;}
::-moz-selection {background: @color-primary; color: #FFF;}


.shifter {position: relative; width: 100%; left: 0; }
.shifter-overlay {position: absolute; top:0; bottom: 0; left: 0; right: 0; background: rgba(0,0,0,0.45); z-index: 20000; display: none;}

.media-state {display: none; position: absolute; height: 0; width: 0; right: 0; bottom: 0; z-index: 3;}

/* ---------------------
 Universal
 --------------------- */

.view-more {
    display: inline-block;
    position: relative;
    font-size: 12px;
    font-size: 0.75rem;
    color: inherit;
    .transition;
    &:after {
        font-family: FontAwesome;
        content: "\f178";
        color: @color-primary;
        display: inline-block;
        margin: 0 0 0 .5rem;
        position: relative;
        top: 1px;
    }
    
    &:hover {color: @color-primary;}
}

.icon-title {
    display: inline-block;
    font-weight: 700;
    font-size: 24px;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    text-transform: uppercase;
    color: inherit;
    
    &:before {
        display: inline-block;
        font-family: FontAwesome;
        font-weight: 400;
        margin: 0 .8rem 0 0;
        color: @color-primary;
        position: relative;
    }
}

.icon-title-small {
    .icon-title;
    font-size: 18px;
    font-size: 1.125rem;
    &:before { margin: 0 0.5rem 0 0; }
}

.view-all {
    position: relative;
    font-size: 14px;
    font-size: 0.875rem;
    top: -3px;
    margin-left: 1.5rem;
    padding: 0.25rem 0 0.25rem 1.5rem ;
    text-transform: uppercase;
    color: @color-primary !important;
    border-left: 1px solid #CCC;
    .transition;
    &:hover {color: inherit !important;}
}

.customTitle {
    display: inline-block;
    font-weight: 700;
    font-size: 24px;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    text-transform: uppercase;
    color: inherit;
    
    &.enabledIcon {
        .ctIconLeft {
            width: 1.5rem;
            height: 1.5rem;
            top: -1px;
            display: inline-block;
            vertical-align: middle;
            background-size: cover;
            position: relative;
            margin-right: 0.375rem;
        }
    }
}

.under-banner {
    background: #262626;
    height: 0.25rem;
}

.bottomnav {
    text-align: center;
    ul {list-style-type: none; padding: 0;}
    li { display: inline-block; }
    li+li:before {content :"|"; padding: 0 0.625rem;}
}


/* ---------------------
	 Topbar
	 --------------------- */
.topbar {
    background: #FFF;
 
    .site-title {
        font-weight: 100;
        margin: 0;
        display: inline-block;
        text-transform: none;
        font-size: 48px;
        line-height: 1; 
    }
    
    .topbar-content {display: table; width: 100%; margin-bottom: 0%;}
    
    .logo-container,
    .logo-text-container,
    .round-link-container {display: table-cell; }
    
    .logo-container {width: 15%; position: relative; 
        .logo {
                position: inherit;
                display: block;
                max-width: 150px;
                width: 100%;
                z-index: 5;
                top: 0;

                img {max-height:150px; display: block; max-width: 100%; margin: 10px auto;}
            }
    }
    .logo-text-container {width: 42.5%; vertical-align: middle; padding: 2%;}
    .round-link-container {width: 42.5%;}
    
    .round-links {
        padding: 30px 0;
        width: 100%;
        li {
            display: inline-block;
            width: 24%;
        }
        a {
            display: block;
            border: 1px solid #bbc0c7;
            border-radius: 50%;
            height: 86px;
            width: 86px;
            margin: 0 auto;
            text-align: center;
            font-size: 11.5px;
            font-weight: 600;
            color: #262c36;
            background-color: #fff;
            padding: 20px 0;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            
            .transition;
            
            &:hover {
                border-color: @color-primary;
                background-color: @color-secondary;
                color: #fff;
                -moz-box-shadow: 0 0 10px 1px rgba(1,29,58,.3);
                -webkit-box-shadow: 0 0 10px 1px rgba(1,29,58,.3);
                box-shadow: 0 0 10px 1px rgba(1,29,58,.3);
            }
        }
        i {
            font-size: 24px;
            display: block;
            color: @color-primary;
        }
    }

    .hidden {
        display: none;
    }


}// end topbar




/* ---------------------
     Header Quicklinks
     ---------------------*/

/*These styles enable the touch "toggle open" effect*/
.hover_effect1,
.hover_effect2,
.hover_effect3 {
  visibility: visible !important;
}

.header-quicklinks {
    font-size: 12px;
    background: @color-secondary;

    /*Header-Quicklinks Divider*/
    span.divider {
    color: contrast(@color-secondary, #111, #fff);
    margin-left: 10px;
    }
    
    .dropdown {
        display: inline-block;
        margin: 0;
        padding: 0;
        letter-spacing: 0.5px;
        font-size: 100%;
        font-family: @font-secondary;
        i {
            font-size: 20px;
            margin-right:2px;
        }
        > li {
            display: inline-block;
            /*background: darken(@color-primary, 5%);*/ 
            &:hover {
                /*background: darken(@color-primary, 10%);*/
            }
            a {
                padding: 0px;
                display: inline-block;
                color: contrast(@color-secondary, #fff, darken(@color-secondary, 60%));
                &:hover {
                    color: lighten(@color-primary, 30%);
                }
            }
            .call-map{
                position: absolute;
                top: 0px;
                left: 6px;
                span {
                padding: 12px;
                }
                a{
                    color: #fff;
                    text-decoration: none;
                }
                .contact-phone{
                    margin-left: 10px;
                }
                .contact-map {
                    margin-right: 5px;
                }

            }
        }
        .search-icon-mobile{display: none;}/*Secondary Search for Mobile*/
        
        div {
            position: absolute;
            visibility: hidden;
            background: darken(@color-primary, 10%);
            a {
                color: contrast(darken(@color-primary, 10%), #fff, #111);
                display: block;
                min-width: 160px;
                line-height: 40px;
                padding:0px 10px;
                white-space: nowrap;
                border-bottom: 1px solid lighten(@color-primary, 10%);
                &:hover {
                    background: lighten(@color-primary, 20%);
                    color: darken(@color-primary, 20%);
                }
            }
            ul {
                margin: 0;
                padding: 0;
                list-style: none;
            }
        }
    }
    
    ul {
        .list-unstyled;
        li {
            display: inline-block;
            line-height: 50px;
            margin-right: 15px;
            .phone-main {
                text-decoration: underline;
            }
        }
        a {
            color: contrast(@color-secondary, #fff, darken(@color-secondary, 60%));
            font-weight: 600;
            text-align: center;
            .transition;
            i {
                color: @color-primary;
                .transition;
            }
            
            &:hover {
                color:@color-primary;
                i {
                     color: #FFF;
                }
            }
        } 
    }
    
    .resources {
        color: #FFF;
        
        span {
            font-weight: 300;
            margin-right: 15px;
            &:before {
                display: inline-block;
                font-family: "Material Icons";
                content: "\E7FB";
                color: @color-primary;
                font-weight: 400;
                font-size: 18px;
                line-height: 0;
                position: relative;
                top: 5px;
                margin: 0 .5rem 0 0;
            }
        }
        a {
            text-transform: uppercase;
        }
    }
    
    .util {
        i {
            font-size: 20px;
            margin-right: 7px;
        }
    }
    
    .search-button {
        background: @color-secondary;
        padding: 0 22px;
        margin-left: 10px;
        display: inline-block;
        color: #FFF;
        i {font-size: 175%;}
        &:hover {cursor: pointer;}
    }

    .search-bar {
        background: lighten(@color-secondary, 10%);
        position: relative;
        padding: 0 50px 0 10px;
        line-height: 50px;
        z-index: 99;
        width: auto;

        i {
            font-size: 175%;
            margin: 0 10px;
            opacity: 0.7;
            .transition;
            &:hover {
                cursor: pointer;
                opacity: 1;
            }
        }

        input {
            font-family: @font-secondary;
            font-size: 13.5px;
            width: 100%;
            border: none;
            background: transparent;
            color: contrast(@color-secondary, #fff, darken(@color-secondary, 60%));
            outline: none;
            
            &::placeholder{color: contrast(@color-secondary, #fff, darken(@color-secondary, 60%));}
        }

        button {
            background: transparent;
            color: contrast(@color-secondary, #fff, darken(@color-secondary, 60%));
            padding: 0;
            position: absolute;
            right: 0;
            top: 0;
            width: 50px;
            height: 100%;
        }
        .ada {color:#ffffff;}
    }

}

//Search
.search {
    position: relative;
    margin-left: 30px;

    & > a {
        display: inline-block; 
        color: #FFF; 
        height: 36px;
        line-height: 33px;
        text-align: center;
        font-size: 0.6875rem;
        text-transform: uppercase;
        vertical-align: middle;
        outline: none;
        border: none;

        i {
            color:@color-primary;
            font-size: 1rem;
            margin-right: 10px;
            vertical-align: middle;
            margin-top: -3px;
        }


//        &:focus, &:hover {
//            & + div {display: block !important;}
//        }

        &.open + div {display: block !important;}
        
        &:hover i { 
            color: @color-secondary;
        }
    }

    #m3 { 
        position: absolute;
        white-space: nowrap;
        line-height: initial;
        right: -4px;
        left:initial;
        top: 28px;
        padding: 5px;
        background: @color-primary;
        border-radius: 2px;

        &:hover { display: block !important; }

        &:before {
            content: " ";
            display: block;
            width: 0;
            height: 0;
            border-bottom: 6px solid @color-primary;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            top: -6px;
            position: absolute;
            right: 8px;
        }

        input {
            display: inline-block;
            height: 34px;
            border: none;
            border-radius: inherit;
            width: 190px;
            vertical-align: bottom;
        }

        button {
            display: inline-block;
            height: 34px;
            background: #FFF;
            color: #666;
            border: none;
            vertical-align: bottom;
        }

        input:focus, button:focus {border: none; outline: none;}

    }

}

/* ---------------------
	 Header
	 --------------------- */
.header-mobile {display: none;}

.header {
    background: @color-secondary;
    position: relative;
    z-index: 2;
    #shadow > .box-shadow(0, 0, 15px, fade(#000,30%));

    .v-center:before {
      content: '';
      display: inline-block;
      height: 100%;
      vertical-align: middle;
    }

} // end header


/* ---------------------
	 Navigation
	 ---------------------*/
.mobile-nav-button {
    display: none;
    height: 40px;
    background: lighten(@color-secondary, 40%);
    border-top: 1px solid lighten(@color-secondary, 70%);
    border-bottom: 1px solid lighten(@color-secondary, 70%);
    text-align: center;
    font-size: 16px;
    font-size: 1rem;
    line-height: 40px;
    line-height: 2.5rem;
    text-transform: uppercase;
    color: contrast(lighten(@color-secondary, 40%), #111, #FFF); 
    
    .mobile-nav-hit-area{color:contrast(lighten(@color-secondary, 40%), #111, #FFF);}
    
    .mobile-nav-hit-area:before {
        display: inline-block;
        content: "\f0c9";
        font-family: @font-icon;
        padding-right: 5px;
    }
}

.sticky {  
    position: fixed !important;
    width: 100% !important;
    top: 0 !important;
    z-index: 999 !important;
}

.navbar {
    position: relative;
    background: @color-navbar;
    border-top: 1px solid #3e4650;
    border-bottom: 1px solid #3e4650;
    
    
    .mobile-control {display: none;}
    
    .menu-arrow {
        font-size: 20px !important;
        font-size: 1.25rem !important;
        margin-left: 6px;
        margin-left: 0.375rem;
    }
    
    .menu-list { 
        padding: 0 0 0 0%; 
        list-style-type: none;
        display: table;
        width: 100%;
        margin: 0;
        position: static;
    }
    
    .menu-list > li {
        display: table-cell;
        position:relative;
        white-space: nowrap;
        
        /* Main Nav Links */
        & > a {
            display: block;
            color: #FFF;
            font-family: @font-tertiary;
            font-size: 20px;
            font-size: 1.25rem;
            text-transform: uppercase;
            padding: 10px 0 10px 25px;
            padding: 0.625rem 0 0.625rem 1.5625rem;
            font-weight: 700;
            line-height: 1.6;
            .transition;
            outline: none;
        }
        
        &:hover {
            & > a {
                color: contrast(@color-navbar, lighten(@color-navbar, 55%), darken(@color-navbar, 55%));
                background: @color-navbar;
            }

            /* On hover, display 1st child menu */
            & > ul {
                left: 0;
                opacity: 1;
            }

        }
        
        /* For primary links */
        &.has-children {
            & > a:after {
                content: "\f107";
                font-family: FontAwesome;
                font-size: 20px;
                font-size: 1rem;
                padding: 0 0 0 0.5rem;
                position: relative;
            }
        }
        
        
      
        /* Universal child menu styles/resets */
        ul {
            list-style-type: none;
            position: absolute;
            width: 200px;
            left: -9999px;
            padding: 0 !important;
            border: 0 !important;
            opacity: 0;
            .transition(opacity 0.4s ease);
            
            li {position:relative;
                white-space: normal;}
            
            a {
                color: #FFF;
                display: block;
                font-family: proxima-nova,sans-serif;
                font-weight: 600;
                font-size: 13px;
                font-size: 0.8125rem;
                border-top: 1px solid @color-secondary;
                padding: 10px 25px;
                padding: 0.625rem 1.5625rem;
                line-height: 1.5;
                background: inherit;
                .transition;
                outline: none;
                
                &:hover {
                    background: @color-secondary;
                    color: desaturate(lighten(@color-secondary, 35%), 10%);
                }
            }
            
            
        }
        
        /* Iems with child menus */
        .has-children {
            & > a:after {
                content: "\f105";
                font-family: FontAwesome;
                font-size: 12px;
                font-size: 0.75rem;
                padding: 0 0 0 0.75rem;
                position: relative;
            }
            
            /* On hover - display direct descendent */
            &:hover > ul {
                left: 200px;
                top:0;
                opacity: 1;
            }
        }
        
        
        /* 1st child menu styles */
        & > ul {
            background: lighten(@color-secondary, 12%);
            
            & > li {
                a {background: transparent;}
            }
        }
        
        /* 2nd -> nth child menu styles */
        & > ul > li ul {
            right: -200px;
            background: contrast(@color-secondary, desaturate(lighten(@color-secondary, 27%), 10%), desaturate(darken(@color-secondary, 27%), 10%));
            & > li {
                &:first-of-type:before {
                    content: " ";
                    display: inline-block;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -7px;
                    width: 0; 
                    height: 0; 
                    border-right: 7px solid #FFF; 
                    border-top: 7px solid transparent;
                    border-bottom: 7px solid transparent;
                }
                
                a {
                    color: contrast(desaturate(lighten(@color-secondary, 27%), 10%), #fff, #111, 35%);
                    border-left: 1px solid #FFF;
                    background: transparent;
                }
            }
        }
    } 
}




/* ---------------------
	 Banner
	 --------------------- */
.banner {
    .grid {
        max-width: 1600px !important; 
        padding: 0 !important;
        .col {padding: 0;}
    }

    .ccms-banner-overlay{
        h1{
            font-size: 1.5rem;
        }
    }

    .slick-dots{
        position: absolute;
        max-width: 80%;
        width: auto;
        left: 45% !important;
        bottom: -14px !important;
        padding: 0;
    }

    .slick-dots{
        li{
            margin: 0 13px 0 0;
        }

        a{
            padding: 6px 10px;
        }
    }
    
    .ccmsSide {
        background: #FFF;
        padding: 18px !important;
        
        .side-button {
            min-height: 105px;
            background: @color-secondary;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            text-align: center;
            display: block;
            position: relative;
            
            &:before {
                display: block;
                position: absolute;
                content: " ";
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: @color-secondary;
                opacity: 0.8;
                .transition;
            }
            
            &:hover:before {
                background: @color-primary;
            }
            &:hover span{color:contrast(@color-primary, #fff, #111);}
            span {
                display: block;
                position: relative;
                top: 50px;
                color: contrast(@color-secondary, #fff, darken(@color-secondary, 60%));
                font-size: 30px;
                font-size: 1.875rem;
                margin: 0 auto;
                text-align: center;
                line-height: 0;
                font-weight: 700;
                text-transform: uppercase;
                text-decoration: none;
                height: 100%;
                width: 100%;
            }
            
            &+.side-button {
                margin-top: 18px;
            }
        }
    }

}


/* ---------------------
	 Slide Away
--------------------- */
.still-slider {
    min-height: 20px;
    border-top: 1px solid #FFF;
    background: @color-secondary;
}

.slide-away {
    min-height: 20px;
    border-top: 1px solid #FFF;
    position: relative;
    background: @color-secondary;
    background: -moz-linear-gradient(left,  @color-secondary 50%, #cccccc 50%);
    background: -webkit-linear-gradient(left,  @color-secondary 50%,#cccccc 50%);
    background: linear-gradient(to right,  @color-secondary 50%,#cccccc 50%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='@color-secondary', endColorstr='#cccccc',GradientType=1 );

    
    .sa-title, .sa-content {
        width: 50%;
        float: left;
        min-height: 85px;
        padding: 30px 0;
        
        .half-container {
            max-width: @desktop-container / 2;
            padding-left: @container-padding;
            padding-right: @container-padding;
            position: relative;
        }
    }

    .sa-title .half-container {margin-right: 0; margin-left: auto; padding-right: 70px;}
    .sa-content .half-container {margin-left: 0; margin-right: auto;}
    
    .sa-title {
        color: #FFF;
        background: @color-secondary;
        position: relative;
        z-index: 3;
        cursor: pointer;
        &:after {
            position: absolute;
            right: 3%;
            top: 50%;
            .transform(translateY(-50%));
            font-family: FontAwesome;
            content: "\f0da";
            font-size: 105px;
            font-size: 6.5625rem;
            line-height: 0;
            color: #646d77;
            .transition;
        }
        
        &:hover:after {
            color: @color-primary;
        } 
    }
    
    .sa-cover {
        position: absolute;
        top: 0;
        left: 50%;
        right: 0;
        bottom: 0;
        background: @color-secondary;
        height: 100%;
        width: 50%;
        
        .rspPane & {display: none;}
    }
    
    .sa-content {
        background: #CCC;
    }
}


/* ---------------------
	 Awards
--------------------- */
//.awards {
//    border-top: 1px solid #FFF;
//    position: relative;
//    
//    .award-title, .award-images {
//        width: 50%;
//        float: left;
//        min-height: 150px;
//    }
// 
//    .award-title {
//        background: @color-secondary;
//        padding: 0.9375rem 0 0 40px;
//        position: relative;
//        z-index: 3;
//        cursor: pointer;
//
//        .logo, .trophy, .title {float: left;}
//    
//        .logo {max-height: 130px;
//            @media (max-width: 1070px) {
//                display: none;
//            }
//        }
//        
//        .trophy {
//            font-size: 72px;
//            font-size: 4.5rem;
//            color: @color-primary;
//            padding-top: 1.375rem;
//            line-height: 1;
//            margin: 0 16px;
//        }
//        .title {
//            padding-top: 1.25rem;
//            
//            h3 {
//                color: #fff;
//                text-transform: none;
//                margin: 0;
//                font-weight: 600;
//                font-size: 22px;
//                font-size: 1.375rem;
//            }
//
//            h6 {
//                color: #fff;
//                margin: 0;
//                font-size: 48px;
//                font-size: 3rem;
//                text-transform: uppercase;
//                line-height: 1;
//                font-weight: 100;
//            }
//        }
//
//        &:after {
//            position: absolute;
//            right: 6%;
//            top: 70px;
//            font-family: FontAwesome;
//            content: "\f0da";
//            font-size: 105px;
//            font-size: 6.5625rem;
//            line-height: 0;
//            color: #646d77;
//            .transition;
//        }
//        
//        &:hover:after {
//            color: @color-primary;
//        }
//        
//    }
//    
//    .award-cover {
//        position: absolute;
//        top: 0;
//        left: 50%;
//        right: 0;
//        bottom: 0;
//        background: @color-secondary;
//        height: 100%;
//        width: 50%;
//    }
//    
//    .award-images {
//        background: #CCC;
//        padding: 15px 25px;
//        
//        .a-img-container {
//            display: inline-block;
//            width: 32%;
//            text-align: center;
//            
//            &+& {margin-right: 5%;}
//            
//            a{display:block;}
//            
//            img {
//                max-width: 100%;
//                max-height: 127px;
//                opacity: 1;
//                .transition;
//            }
//            
//            a:hover img { opacity: 0.7;}
//        }
//    }
//}


/* ---------------------
	 Latest
--------------------- */
.latest-container {background: #FFF;}

.latest-news {
    margin: 0 0.25rem 0.3125rem 0;
    padding: 1.25rem 0;
    .icon-title:before {content:"\f09e";}
}

.latest-color-box {
    padding: 1.25rem;
    margin: 0 0 0 0.25rem;
    .icon-title:before {content: "\f03d";}
    color: contrast(@color-secondary, #fff, darken(@color-secondary, 60%));
    background: @color-secondary;
    position: relative;
    .ada {color: contrast(@color-secondary,#ffffff,#111111);}
    .RadDock_Catapult div.rdContent {
        color: #fff;
    }
    
    .video-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; margin: 0 0 1.25rem;
        iframe { position: absolute; top: 0; left: 0; width: 100% !important; height: 100% !important; outline: none; border: none;}
    }
    
    .video-thumbs 
    {
        margin:0;
        padding: 0;
        
        li {
            display: inline-block;
            list-style: none;
            width: 30%;
            float: left;
            cursor: pointer;
            border: 1px solid lighten(@color-secondary, 15%);
            padding: 0.25rem;
            .transition;
            
            &+li {margin: 0 0 0 5%;}
            
            img {display: block; max-width: 100%; }
            
            &:hover {border: 1px solid @color-primary;}
        }
    }
}

.big-news-snippet { 
    padding: 1.5rem;
    color: #fff;
	background: @color-secondary;
    background: linear-gradient(fade(@color-secondary, 70%), fade(@color-secondary, 70%)), url("../images/banner-2.jpg");
    background-size: cover;
    background-repeat: no-repeat; 
    border: 1px solid #FFF;
    -webkit-box-shadow: 0 0 10px #888;
    -moz-box-shadow: 0 0 10px #888;
    box-shadow: 0 0 10px #888;
    margin-bottom: 1rem;
    
    .news-title { font-size: 32px; font-size: 2rem; font-weight: 600; line-height: 1.2; margin-bottom: 0.25rem; color: inherit; .transition;
        &:hover {color: @color-primary;}
    }
    
    .news-preview { line-height: 1.5; margin: 0; font-size: 18px; font-size: 1.125rem;
        a {color: @color-primary; .transition;
            &:hover { color: #FFF;}
        }
    }
}

.news-snippet { .clearfix;
    .news-thumb { display: block; border: 1px solid #CCC; padding: 2px; background: #FFF; overflow: hidden; float: left; margin-right: 12px; box-sizing: border-box;
        img { max-width: 90px; max-height: 90px; display: block;}
    }
    .news-title { font-size: 22px; font-size: 1.375rem; font-weight: 600; line-height: 1.2; margin-bottom: 0.25rem;}
    .news-preview { line-height: 1.5; margin: 0; font-size: 14px; font-size: 0.875rem;
        a {color: @color-primary; .transition;
            &:hover { color: @color-secondary;}
        }
    }
    
    &+& {border-top: 1px solid #CCC; margin-top: 1rem; padding-top: 1rem;}
}

.small-news-snippet {
    .news-snippet;
    .news-title {font-size: 14px; font-size: 0.875rem;}
    .news-preview {color: #999; font-size: 13px; font-size: 0.8125rem;}
}

/* ---------------------
	 Dashboard
--------------------- */
a.dashboard-item {
	display: block;
    background: #FFF;
    border: 0.0625rem solid darken(@color-primary, 30%);
    padding: 0.0625rem;
	overflow: hidden;
    border-radius: 0.125rem;
	#shadow > .box-shadow (0, 0, 0.25rem, rgba(0,0,0,0.35));
    opacity: 1;
    .transition;
	
	.image {
		padding-bottom: 56.25%;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}
	
	figcaption {
	    text-align: left;
		padding: 0.25rem;
		color: @color-text;
	}
	
	h3 {
	    margin-top: 0.0625rem;
		border-bottom: 0.0625rem solid #CCC;
		text-transform: uppercase;
		font-size: 0.9125rem;
	}
    
    &:hover {opacity: 0.8;}
}


/* ---------------------
	 Info Modules
--------------------- */
.info-modules-container {
    background: @color-secondary;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    position:relative;
    
    .overlay {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: fade(@color-primary, 85%);
    }
    
    .sporting-news, .upcoming-events, .on-twitter {
        padding: 20px;
        margin-bottom: 30px;
        background: #FFF;
    }
    
    .sporting-news {
        .icon-title-small:before {content: "\f1b0";} 
    }
    
    .upcoming-events {
        .icon-title-small:before {content: "\f274";}
        
        .event {
            .clearfix;
            position: relative;
            margin-bottom: 0.5625rem;
            
            a {color: @color-primary; .transition;
                &:hover {color: inherit;}
            }
            
            .event-date {
                float: left;
                background: lighten(@color-secondary, 10%);
                color: #FFF;
                font-size: 15px; 
                font-size: 0.9375rem;  
                text-transform: uppercase;
                line-height: 1;
                height: 55px;
                width: 55px;
                text-align: center;
                padding-top: 8px;
                margin: 3px 10px 3px 0;

                span {
                    display: block;
                    font-weight: 700;
                    font-size: 28px;
                    font-size: 1.75rem;
                }
            }

            .event-info {
                margin-left: 65px;
                font-size: 13px;
                font-size: 0.8125rem;

                .event-title {
                    display: block;
                    font-size: 16px;
                    font-size: 1rem;
                    font-weight: 700;
                }
                
                .event-where {display: block;}
            }
        }

        .view-more {
            padding-top: 0.5rem;
            margin-top: 0.75rem;
            &:before {
                display: block;
                content: " ";
                height: 1px; width: 30%;
                border-top: 1px solid @color-primary;
                position: absolute;
                top: 0;
            }
        }  
    }
    
    .on-twitter {
        .icon-title-small:before {content: "\f099";}
    }
    
    
    .social-links {
        background: #FFF;
        padding: 10px 10px 6px;
        text-align: center;
        
        .social-text {
            margin-right: 10px;
            font-size: 1rem;
            font-weight: bold;
        }
        
        .social-button {
            padding: 0.125rem 0.6125rem;
            font-weight: bold;
            margin: 0.3125rem 0.1875rem 0.625rem 0.3125rem;
            color: @color-secondary;
            background-color: #bbc0c7;
            display: inline-block;
            -webkit-border-radius: 2px !important;
            -moz-border-radius: 2px !important;
            border-radius: 2px !important;
            letter-spacing: 0;
            font-size: 14px;
            font-size: 0.875rem;
            .transition;
            
            &:hover {
                color: #FFF;
                background: @color-primary;
            }
            
        }
        
    }
    
    
//    .daily-bulletins {
//        background: #FFF;
//        padding: 20px;
//        .icon-title-small {
//            margin-bottom: 0;
//            &:before {content: "\f022";}
//        }
//        
//        .date { 
//            background-color: #bbc0c7;
//            color: @color-secondary;
//            font-weight: 700;
//            padding: 0.125rem 0.6125rem;
//            border-radius: 2px;
//            letter-spacing: 0;
//            font-size: 14px;
//            font-size: 0.875rem;
//            display: inline-block;
//            text-transform: uppercase;
//            margin-left: 3%;
//            .transition;
//            
//            &:hover {
//                color: #FFF;
//                background: @color-primary;
//            }
//        }
//        
//        .view-more {
//            float: right;
//            padding-top: 0.5rem;
//            text-transform: uppercase;
//            margin: 0;
//        }    
//    }
}


/* ---------------------
 Content
 --------------------- */

.content {
    position: relative;
    z-index: 0;
    
    .article {
        .search_cont_content {
            .header-search-field {
                background-color: #fff;
                background-image: none;
                border-color: #999;
                border-radius: 7px 0 0 7px;
                border-style: solid;
                border-width: 1px 0 1px 1px;
                box-sizing: border-box;
                color: #53585c;
                float: left;
                height: 35px;
                margin: 0;
                padding: 0 7px;
                vertical-align: top;
                width: 164px;
            }
            .header-search-button {
                box-sizing: border-box;
                color: #555;
                height: 35px;
                margin: 0;
                vertical-align: top;
            }
        }// end search content 
    }
} // end content 


/* ---------------------
 Sidenav
 --------------------- */
.sidenav {
    border: 0.0625rem solid #fff;
    background-color: #fff;
    -moz-box-shadow: 0 0 0.375rem 0.125rem #d9dfe4;
    -webkit-box-shadow: 0 0 0.375rem 0.125rem #d9dfe4;
    box-shadow: 0 0 0.375rem 0.125rem #d9dfe4;
    a {color: darken(@color-secondary,55%); .transition;
        &:hover { color: @color-primary;}
    }
    
    .sidenav-title {
        background-color: @color-secondary;
        color: contrast(@color-secondary, #111, #fff);
        font-size: 20px;
        font-size: 1.25rem;
        text-align: left;
        padding: 0.4375rem 0.625rem;
        text-shadow: none;
        margin: 0;
        font-weight: bold;
        text-transform: uppercase;
        
        i {
            color: rgba(255,255,255,0.45);
            padding-right: 0.375rem;
        }
    }
    
    /* All ULs */
    ul {
        list-style-type: none;
        padding: 0;
        margin:0;
        background: #ffffff;
        ul {
            background: #f5f5f5;
            a {padding: 0rem 0.25rem 0rem 1.75rem;}
            ul{
                background: #f0f0f0;
                a {padding: 0rem 0.25rem 0rem 2.25rem;}
                ul{
                    background: #e5e5e5;
                    a {padding: 0rem 0.25rem 0rem 2.75rem;}
                }
            }
        }
    }
    
    /* All LIs */
    li {
        position:relative;
        border-top: 0.0625rem solid rgba(0,0,0,.2);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        
        a { 
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
            font-weight: 600;
            padding: 0rem 0.25rem 0rem 1.25rem;
            
            i {font-style: normal !important;}
        }
    }
    
    &>ul>li {
        & > span {display: none !important;}
        & > a {
            font-style: normal !important;
            font-size: 18px;
            font-size: 1.125rem;
        }
    }
    
    .side-active {
        & > a {
            color: contrast(@color-primary, #ffffff, #111111);
            background: @color-primary;
        }
        &>ul {display:block;}
        span.plus, span.minus {color: contrast(@color-primary, #ffffff, #111111);}
    }
    
    
    &>ul>li>ul li{
        &>ul {display: none;}
        
        a {
            height:2rem; 
            line-height: 2rem; 
        }
    }
    
    span.plus, span.minus {
        display: block;
        width: 1.25rem;
        text-align: center;
        padding: 0.25rem 0;
        height: 2rem; 
        position: absolute;
        top: 0;
        left: 0;
        color: @color-primary;
        font-weight: bold;
        font-size: 22px;
        font-size: 1.375rem;
        cursor: pointer;
        
        &:hover:before {
            color: @color-text;
        }
    }
    
    span.plus {
        line-height: 1.5rem;
        &:before {content: "+";}
    }
    
    span.minus {
        line-height: 1.3125rem;
        &:before {content: "-";}
    }
    
    
    
}


/****************************
*   ANNOUNCMENT OVERRIDES
*****************************/

#catapultannounce-content {
    li.catapultannounce-item.catapultannounce-item-priority,
    li.catapultannounce-item.catapultannounce-item-priority:nth-child(2n),
    li.catapultannounce-item:nth-child(2n) {background-color: transparent;}
    date.catapultannounce-date {visibility: hidden;}
    
    li.catapultannounce-item { border-bottom: none; padding: 10px 0;
        &:hover {
            background-color: transparent !important;
            span.readmore { 
                background: none;
                color: @color-secondary;
            }
        }
    }
    
    .catapultannounce-title {
        font-family: @font-tertiary;
    }
    
    a.all-catapultannounce-link {
        color: inherit;
        border: 1px solid @color-secondary;
        font-weight: 400;
        text-decoration: none;
        font-size: 85%;
        font-style: italic;
        
        &:hover {
            background: @color-primary;
            color: #000;
        }
    }
    
    .catapultannounce-image-holder .catapultannounce-image {
        outline: none;
        border: 3px solid @color-secondary;
        padding-bottom: 100%;
    }
    
    .catapultannounce-description {font-size: 90%;}
    
    .catapultannounce-feed-view .catapultannounce-list li.catapultannounce-item:first-of-type 
    {
        border-top: none;
    }
    
    /* Trying to force span.readme onto its own line breaks the announcement loader.
     * My guess is that doing so screws with dotdotdot (js) which in turn removes most,
     * if not all, of the description text from the DOM.
    */
    
    .catapultannounce-item span.readmore {
        color: inherit;
        border: none;
        margin-left: 5px;
        margin-top: 0;
        padding: 0;
        font-size: inherit;
        font-weight: 400;
        text-decoration: underline;
        font-style: italic;
    }
}

.latest-container a:hover{
	color: inherit;
    background-color: lighten(@color-primary, 50%);
}

.latest-container a .catapultannounce-image {
    transition: background-size .1s ease-in;
    -moz-transition: background-size .1s ease-in;
    -ms-transition: background-size .1s ease-in;
    -o-transition: background-size .1s ease-in;
    -webkit-transition: background-size .1s ease-in;
    background-size: 145%;
}

.latest-container a:hover .catapultannounce-image {
    background-size: 160%;
}

/* ---------------------
	 Footer Top
	 --------------------- */
.footer-top {
    text-transform: uppercase;
    border-top: 2px solid #fff;
    background: @color-secondary;
    color: @color-primary;
    font-family: @font-tertiary;
    font-size: 35px;
    font-size: 2.1875rem;
    
    .col:last-child {text-align: right;}
}

/* ---------------------
	 Footer
	 --------------------- */
.footer {
    color: contrast(lighten(@color-secondary, 20%), #111, #FFF);
	background: @color-secondary;
    background-size: cover;
    background-repeat: no-repeat;
    position:relative;
    
    .footer-overlay {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: fade(lighten(@color-secondary, 20%), 80%);
    }
    
    .grid {
        max-width: 1200px !important;
        position: relative;
    }

    .logo img {max-width: 100%;}
    
    li, a {color: contrast(lighten(@color-secondary, 20%), #111, #FFF);}
    a { 
        text-decoration: underline;
        &:hover {color: #fff;text-decoration: none;}
    }

    #quicklinks {
        padding-left: 100px;
        ul {
            .list-unstyled;
            .columns(50%, 2, 20px); 
            a {
                &:before {
                    content:'\f105';
                    font-family: @font-icon;
                    margin-right: 5px;
                }
            }
        }
    }
    
    .contact-info {
        h5 {text-transform: uppercase; margin-bottom: 4%; font-size: 25px;}
        a {
            color: contrast(lighten(@color-secondary, 20%), #111, #FFF);
            text-decoration: none;
            .transition;
            &:hover {
                color: @color-primary;
            }
        }
        .address {
            margin-bottom: 24px;
            margin-bottom: 1.5rem;
            font-size: 15px;
            font-size: 1.375rem;
            
            .view-more {
                margin: 14px 0 0 45px;
                margin: 0.875rem 0 0 2.8125rem;
            }
            
            
            &:before {
                font-family: FontAwesome;
                content: "\f041";
                display: inline-block;
                font-size: 45px;
                font-size: 1.375rem;
                line-height: 1;
                float: left;
                margin: .1rem 1rem 0 0;
                color: @color-primary;
            }
        }
        
        .contact {
            
            .phone, .fax {
                font-size: 22px;
                font-size: 1.375rem;
                margin-bottom: 6px;
                margin-bottom: 0.375rem;
            }
            
            .phone, .fax, .email {
                &:before {
                    font-size: 22px;
                    font-size: 1.375rem;
                    font-family: FontAwesome;
                    color: @color-primary;
                    display: inline-block;
                    position: relative;
                }
            }
  
            .phone {
                line-height: 1;
                &:before {
                    content: "\f098";
                    margin: 0 .8rem 0 0;
                }
            }
            
            .fax:before {
                    content: "\f1ac";
                    margin: 0 .6rem 0 0;
            }
            
            .email {
                line-height: 1;
                font-size: 22px;
                font-size: 1.375rem;
                &:before {
                    vertical-align: middle;
                    content: "\f003";
                    margin: 0 .8rem 0 0;
                }
            }
        }
            
                
            
        }
    .compliance{
        color: #000000;
        text-align: center;
        font-size: 85%;
        padding-top: 20px;
    }
    
    
    .nav-footer {
        font-weight: 600;
        text-transform: uppercase;
        font-size: 12px;
        font-size: 0.75rem;
        border-top: 1px solid #FFF;
        margin-top: 2%;
        ul {
            padding: 0;
            margin: 8px 0;
            margin: 0.5rem 0;
        }
        li {
            display: inline-block;
            &+li {margin-left: 10px;}
        }
    }
    
    .accent-image {
        position: absolute;
        top: -100px;
        
        img {max-width: 300px;}
    }
}

/* ---------------------
	 Copyright
	 --------------------- */
.copyright {
    padding: 15px 0;
    font-weight: 500;
    font-size: 11px;
    font-size: 0.75rem;
    background: @color-body;
    color: contrast(@color-body, desaturate(lighten(@color-body, 55%), 100%), desaturate(darken(@color-body, 55%), 100%));
    a {
        border-bottom: none;
        color:contrast(@color-body, lighten(@color-link, 55%), darken(@color-link, 55%));
        
        &:hover {
            color:contrast(@color-body, #fff, #111);
        }

    }
    
    .catapult {
        font-size: 10px;
        font-size: 0.625rem;
        text-transform: uppercase;
        letter-spacing: 1.5px;
        
        a {
        border-bottom: none;
        color: #f35958;
        }
    }
}

/* ---------------------
     ADA Tools
     --------------------- */

.ada-contrast{
    display: inline-block;
    padding-right:15px;
    a{
    padding-left: 20px;
    color:contrast(@color-body, lighten(@color-link, 55%), darken(@color-link, 55%));
        &:hover{
            color:contrast(@color-body, #fff, #111);
        }
    }
}


/* ---------------------
     Non Descrimination
     --------------------- */

.nondescrimination{
    display:inline;
    i{
        display: inline-block;
        width: 30px;
        height: 30px;
        margin-right: 10px;
        margin-top: -7px;
        background: @color-primary;
        color: contrast(@color-primary, #fff, #111);
        border-radius: 50%;
        line-height: 30px;
        text-align: center;
        -webkit-font-smoothing: antialiased;
        font-weight: bold;
        -webkit-transition: transform 0.1s ease;
        -moz-transition: transform 0.1s ease;
        -ms-transition: transform 0.1s ease;
        -o-transition: transform 0.1s ease;
        transition: transform 0.1s ease;
    }
    &:hover{
            color:contrast(@color-body, #fff, #111);
            i{
            background: @color-link;
            color:contrast(@color-link, #fff, #111);
            }
        }
}


/* ---------------------
    Subpage
 --------------------- */
.sub-page {
    .under-banner {height: 0.0625rem;}
    .pagename h2 {font-size: 2rem;}
    .description h2 {font-size: 2rem;}
    .sub-header { background: #FFF; border-bottom: 0.0625rem solid #e5e5e5;}
    
}