// ==========================================================================
// Tablet Custom Styles
// ==========================================================================


.media-state {z-index: 2;}


/* ---------------------
   Header
   --------------------- */
.header-mobile {display: none;}
.header {

    #header-logo {
        height: 80px;
        width: 80px;
    }

    .grid:not(.header-logo-grid) {padding-left: 40px;}
    
    // Topbar
    .topbar {
        .site-title {
            font-size: 30px;
            font-size: 1.875rem;
        }
        
        .logo-container {width: 18%;}
        .logo-text-container {width: 34%;}
        .round-link-container {width: 48%;}
             
        .round-links a {
            height: 74px;
            width: 74px;
            padding: 16px 0;
        }  
    }
    
    // Navbar
    .navbar {
        .menu-list {
            padding: 0 0 0 18%;
            & > li > a {
                font-size: 1rem;
                padding: 10px 0 10px 4px;
                padding: 0.625rem 0 0.625rem 0.25rem;
            }
        }
    }

    // Header-Quicklinks
    .header-quicklinks {
        span {.font-xs;}
        ul li{margin-right:4px;}
    }

    //Dropdown
    .dropdown {
        i {}
        > li {
            .call-map{
                span {
                padding: 4px;
                }
            }
        }
    }
}

/* ---------------------
     Banner
     --------------------- */
.banner {
    .ccmsSide {
        background: #FFF;
        padding: 18px !important;
        min-height: 65px;
        
        .side-button {
            width: 31.5%;
            float: left;
            min-height: 65px;

            span {
                top: 33px;
                color: #fff;
                font-size: 20px;
                font-size: 1.25rem;
            }
            
            &+.side-button {
                margin-top: 0;
                margin-left: 2.75%;
            }
        }
    } 
}

.sub-page {
    #previewPanel,
    .banner {
        background: #FFF;

        .grid,
        .ccms-banner, 
        .banner-slide, .slider {
            min-height: @banner-height-subpage;
            max-height: @banner-height-subpage;
            overflow: hidden;
        }

        .banner-slide {min-height: @banner-height-subpage;}
        
        .ccms-banner-overlay, .slick-next::before, .slick-prev::before, .slick-prev, .slick-next, .slick-dots {
            display: none !important;
        }
        
    }
}

/* ---------------------
   Awards
   --------------------- */
//.awards {
//    .award-title {
//        padding-left: 25px;
//        
//        .logo {display: none;}
//    }
//}

/* ---------------------
   Content
   --------------------- */
.content {

}// end content


/* ---------------------
   Dashboard
   --------------------- */
a.dashboard-item {
    margin-bottom: @container-padding/2;
}


/* ---------------------
   Info Modules
   --------------------- */
.info-modules-container .daily-bulletins .view-more {
    display: block;
    float: none;
    width: 120px;
}

.info-modules-container .social-links .social-text {
    display: block;
}


/* ---------------------
   Social
   --------------------- */
.social {
  .social-buttons {display: block;}
}

/* ---------------------
   Footer
   --------------------- */
.footer{

  .grid {
      padding-left: 40px;
      &:before {display: none;}

  }

}// end footer