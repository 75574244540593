@media only screen and (max-width: @mobile-breakpoint) {

.container, .mobile-container {
    margin-left: auto;
    margin-right: auto;
    float: none;
    &:first-child {
        margin-left: auto;
    }
}
.mobile-container-full {
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
    float: none;
    &:first-child {
        margin-left: auto;
    }
}

.grid {
    padding-left: 30px;
    padding-right: 30px;
}

.mobile-no-gutter {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

// Mobile Grid
.mobile-col-1-2 {
    width: 50%; // Fallback if calc() is not supported
/*    width: calc(100% / 12 * 6);
    width: -webkit-calc(100% / 12 * 6);
    width: -moz-calc(100% / 12 * 6);
    */
}
.mobile-col-1-3 {
    width: 33.33333%; // Fallback if calc() is not supported
/*    width: calc(100% / 12 * 4);
    width: -webkit-calc(100% / 12 * 4);
    width: -moz-calc(100% / 12 * 4);
    */
}
.mobile-col-1-4 {
    width: 25%; // Fallback if calc() is not supported
/*    width: calc(100% / 12 * 3);
    width: -webkit-calc(100% / 12 * 3);
    width: -moz-calc(100% / 12 * 3);
    */
}
.mobile-col-3-4 {
    width: 75%; // Fallback if calc() is not supported
/*    width: calc(100% / 12 * 9);
    width: -webkit-calc(100% / 12 * 9);
    width: -moz-calc(100% / 12 * 9);
    */
}

// Push/Pull
.mobile-push-1-2 {
    left: 50%; // Fallback if calc() is not supported
/*    left: calc(100% / 12 * 6);
    left: -webkit-calc(100% / 12 * 6);
    left: -moz-calc(100% / 12 * 6);
    */
}
.mobile-pull-1-2 {
    left: -50%; // Fallback if calc() is not supported
/*    left: calc(-100% / 12 * 6);
    left: -webkit-calc(-100% / 12 * 6);
    left: -moz-calc(-100% / 12 * 6);
    */
}
.mobile-push-1-3 {
    left: 25%; // Fallback if calc() is not supported
/*   left: calc(100% / 12 * 3);
    left: -webkit-calc(100% / 12 * 3);
    left: -moz-calc(100% / 12 * 3);
    */
}
.mobile-pull-1-3 {
    left: -25%; // Fallback if calc() is not supported
/*    left: calc(-100% / 12 * 3);
    left: -webkit-calc(-100% / 12 * 3);
    left: -moz-calc(-100% / 12 * 3);
    */
}
.mobile-push-1-4 {
    left: 33.33333%; // Fallback if calc() is not supported
/*    left: calc(100% / 12 * 4);
    left: -webkit-calc(100% / 12 * 4);
    left: -moz-calc(100% / 12 * 4);
    */
}
.mobile-pull-1-4 {
    left: -33.33333%; // Fallback if calc() is not supported
/*    left: calc(-100% / 12 * 4);
    left: -webkit-calc(-100% / 12 * 4);
    left: -moz-calc(-100% / 12 * 4);
    */
}
.mobile-push-3-4 {
    left: 75%; // Fallback if calc() is not supported
/*    left: calc(100% / 12 * 9);
    left: -webkit-calc(100% / 12 * 9);
    left: -moz-calc(100% / 12 * 9);
    */
}
.mobile-pull-3-4 {
    left: -75%; // Fallback if calc() is not supported
/*    left: calc(-100% / 12 * 9);
    left: -webkit-calc(-100% / 12 * 9);
    left: -moz-calc(-100% / 12 * 9);
    */
}

.mobile-no-push, .mobile-no-pull {
    left: auto;
}

// Mobile Rows
.mobile-row {
  padding-top: 1em;
  padding-bottom: 1em;
}

.mobile-col-1-1,
.mobile-full {
    left: auto;
    clear: both;
    float: none;
    width: 100%;
    margin: 0 0 0 0;
    display: block;
}
.mobile-full:first-child {
    margin-top: 0;
}

.mobile-text-left {
    text-align: left;
}
.mobile-text-right {
    text-align: right;
}
.mobile-text-center {
    text-align: center !important;
}

// Floats
.mobile-left {
    float: left;
}
.mobile-right {
    float: right;
}
.mobile-no-float {
    float: none !important;
}

// Remove Margin / Padding
.mobile-no-margin {
    margin: 0 !important;
}
.mobile-no-padding {
    padding: 0 !important;
}

    // Hide on Mobile
.no-mobile {
    display: none !important;
}
.show-mobile {
    display: block !important;
}

@import "../_mobile-styles"; // Import Custom Mobile Styles

} // End Mobile Media Query